<!-- 厂家供货表 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20">
      <!-- 关键字： -->
      <FormItem>
        <span>关键字：</span>
        <Input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.keyword" />
      </FormItem>
      <!-- 物料状态： -->
      <FormItem class="marginLeft51">
        <span>物料状态：</span>
        <Select placeholder="请输入" v-model="queryFrom.product_status" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in productList" :value="item.key" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <!-- 更新时间 -->
      <FormItem class="marginLeft51">
        <span>更新时间：</span>
        <DatePicker type="date" @on-change="changeTime($event, 1)" placeholder="请选择" class="iviewIptWidth250"></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker type="date" @on-change="changeTime($event, 2)" :options="options" placeholder="请选择" class="iviewIptWidth250"></DatePicker>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0 marginLeft15" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query()">查询</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="handImoort()">手动导入</span>
      </FormItem>
    </Form>

    <!-- 手动导入弹窗 -->
    <Modal v-model="IshandImport" title="更新时间" cancel-text @on-cancel="CancelHandImport" width="568">
      <div class="modalContent">
        <p class="modaltitle">请输入更新时间：</p>
        <DatePicker type="date" v-model="handleList.update_time_start" @on-change="changeTime2($event, 1)" placeholder="请选择" class="iviewIptWidth250"></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker type="date" v-model="handleList.update_time_end" @on-change="changeTime2($event, 2)" :options="options2" placeholder="请选择" class="iviewIptWidth250"></DatePicker>
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="SureHandImport">确定</span>
      </div>
    </Modal>

    <!-- 提示弹窗 -->
    <!-- <Modal v-model="tipStatus"
           label-position="left"
           width="400"
           :footer-hide="true"
           :mask-closable="false"
           :closable="false"
           class-name="vertical-center-modal">
      <div class="topModal clearfix">
        <div v-if="showName === 'success'"
             class="content">
          <img src="@/assets/images/tipsSuccessImg.png"
               class="tipsImg fl" />
          <div class="flDiv fl">
            <p>数据同步成功！</p>
          </div>
        </div>
        <div v-if="showName === 'fail'"
             class="content">
          <img src="@/assets/images/tipsDeleteImg.png"
               class="tipsImg fl" />
          <div class="flDiv fl">
            <p>数据同步失败！</p>
          </div>
        </div>
      </div>
      <div class="foot">
        <span class="pageBtn finger marginLeft20 btnSure"
              @click="tipStatus = false">确定</span>
      </div>
    </Modal> -->
    <Table :productList="listColumns" :productData="listData" border class="table" :loading="isLoad" :isLoad="isLoad"></Table>
    <template v-if="listData.length != 0">
      <div class="marginTop20">
        <Page v-if="total <= 60" :total="total" :current="queryFrom.page" @on-change="changePage" class="fl page" />
        <Page v-if="total > 60" show-elevator :total="total" :current="queryFrom.page" @on-change="changePage" class="fl page" />
        <div class="fr totalFont page">共计{{ total }}条记录</div>
      </div>
    </template>
  </div>
</template>

<script>
import Table from '@/components/table'
export default {
  name: 'ProductEqualRecord',
  components: {
    Table,
  },
  data() {
    return {
      isLoad: true,
      IshandImport: false,
      tipStatus: false,
      showName: '',
      // 手动导入弹窗信息
      handleList: {
        update_time_start: null,
        update_time_end: null,
      },
      // 时间限制条件
      options: {},
      options2: {},
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          maxWidth: 90,
          key: 'index',
        },
        {
          title: '物料号',
          key: 'product_model_code',
          align: 'center',
          width: 180,
          minWidth: 100,
        },
        {
          title: '物料描述',
          key: 'product_model_code_desc',
          align: 'center',
          // width: 450,
          minWidth: 400,
        },
        {
          title: '基本单位',
          key: 'product_unit',
          align: 'center',
          width: 90,
        },
        {
          title: '采购单位',
          key: 'purchase_unit',
          align: 'center',
          width: 90,
          // render: (h, param) => {
          //   return h('div', [
          //     h(
          //       'span',
          //       {
          //         class: 'tableBlakcFont',
          //       },
          //       '¥' + param.row.purchase_amount
          //     ),
          //   ])
          // },
        },
        {
          title: '转换比例',
          // key: 'trans_numerator',
          width: 90,
          align: 'center',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.trans_numerator + '/' + param.row.trans_denominator
              ),
            ])
          },
        },
        {
          title: '物料状态',
          key: 'is_effective',
          align: 'center',
          width: 90,
          render: (h, param) => {
            if (param.row.is_effective === 'Y') {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'tableBlakcFont',
                  },
                  '正常'
                ),
              ])
            } else if (param.row.is_effective === 'N') {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'tableBlakcFont',
                  },
                  '禁用'
                ),
              ])
            }
          },
        },
        {
          title: '更新时间',
          // key: 'update_time',
          align: 'center',
          width: 195,
          render: (h, param) => {
            if (this.$moment.unix(param.row.update_time).format('YYYY-MM-DD HH:mm:ss') !== '1970-01-01 08:00:00') {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'tableBlakcFont',
                  },
                  this.$moment.unix(param.row.update_time).format('YYYY-MM-DD HH:mm:ss')
                ),
              ])
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'tableBlakcFont',
                  },
                  ''
                ),
              ])
            }
          },
        },
        {
          title: '更换方式',
          key: 'update_type',
          align: 'center',
          width: 100,
          render: (h, param) => {
            return h('div', [
              h('span', {
                class: 'tableBlakcFont',
              }),
              param.row.update_type_str,
            ])
          },
        },
      ],
      // 物料状态
      productList: [
        { name: '正常', key: 'Y' },
        { name: '禁用', key: 'N' },
      ],
      // 查询参数
      queryFrom: {
        keyword: '',
        product_status: '',
        update_time_start: '',
        update_time_end: '',
      },
      pages: {
        page: 1,
        rows: 10,
      },
      total: 0,
    }
  },
  mounted() {
    this.getproductSynRecordList()
  },
  methods: {
    // 点击手动导入
    handImoort() {
      this.IshandImport = true
      this.handleList = {
        update_time_start: null,
        update_time_end: null,
      }
    },
    // 确定手动导入
    SureHandImport() {
      let data = {
        update_time_start: this.handleList.update_time_start,
        update_time_end: this.handleList.update_time_end,
      }
      if (!data.update_time_start || !data.update_time_end) {
        this.$Message.warning('请输入更新时间！')
        return
      }
      // console.log(data);
      // this.IshandImport = false
      // return
      this.$http.post(this.$api.productSynRecord, data, true).then(res => {
        this.$Message.success('导入成功！')
        this.IshandImport = false
        this.getproductSynRecordList()
      })
    },
    // 取消手动导入
    CancelHandImport() {
      this.IshandImport = false
      this.handleList = {
        update_time_start: null,
        update_time_end: null,
      }
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.update_time_start = e
        that.queryFrom.update_time_end = null
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.update_time_start)
          },
        }
      } else {
        this.queryFrom.update_time_end = e
      }
    },
    changeTime2(e, num) {
      if (num == 1) {
        let that = this
        that.handleList.update_time_start = e
        that.handleList.update_time_end = null
        that.options2 = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.handleList.update_time_start)
          },
        }
      } else {
        this.handleList.update_time_end = e
      }
    },
    // 点击查询
    query() {
      this.pages.page = 1
      this.getproductSynRecordList()
    },
    // 页码改变
    changePage(e) {
      this.pages.page = e
      this.getproductSynRecordList()
    },
    // 产品同步记录
    getproductSynRecordList() {
      let data = {
        page: this.pages.page, // 页
        rows: this.pages.rows, // 行
        keyword: this.queryFrom.keyword,
        product_status: this.queryFrom.product_status,
        update_time_start: this.queryFrom.update_time_start,
        update_time_end: this.queryFrom.update_time_end,
      }
      this.isLoad = true
      this.$http.get(this.$api.productSynRecord, data, true).then(res => {
        this.isLoad = false
        this.total = res.total
        this.listData = res.data
      })
    },
  },
}
</script>

<style scoped lang="less">
.marginTop20 {
  margin-top: 20px;
}
.table {
  /deep/ .ivu-table-summary {
    border-top: 0;
  }
}
.modalContent {
  padding-top: 31px;
  padding-bottom: 30px;
}
.modaltitle {
  margin-bottom: 13px;
  height: 22px;
  font-size: 16px;
  //font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 22px;
}
.foot {
  text-align: right;
}
.topModal {
  padding-bottom: 30px;
  margin-top: 30px;

  .content {
    display: flex;
    align-items: center;
    .tipsImg {
      width: 36px;
      height: 36px;
      margin: 0 13px 0 9px;
    }
    .flDiv {
      p:nth-child(1) {
        display: block;
        height: 24px;
        font-size: 18px;
        font-weight: 600;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
      }
    }
  }
}
</style>
